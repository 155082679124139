import './App.css';
import { Outlet, useLocation } from "react-router-dom";
import SessionRedirect from "../SessionRedirect/SessionRedirect";
import SessionProvider from "../../stores/SessionProvider";
import {AppBar, Box, ThemeProvider, Toolbar, Typography} from "@mui/material";
import theme from "../../theme";

function App() {
  const location = useLocation();
  const should_redirect = location.pathname === "/";

  return (
    <div className="App">
      <SessionProvider>
        <ThemeProvider theme={theme}>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="primary">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Invithings installation
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          {should_redirect ?
            <SessionRedirect/>
            :
            <div className="App_content">
              <Outlet />
            </div>
          }
        </ThemeProvider>
      </SessionProvider>
    </div>
  );
}

export default App;
