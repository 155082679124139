import {useEffect} from "react";
import {
  useNavigate,
} from "react-router-dom";
import {useSessionContext} from "../../stores/SessionProvider";

function SessionRedirect() {
  const navigate = useNavigate();
  const session_context = useSessionContext();

  useEffect(() => {
    const session_in_progress = session_context.is_session_active();
    if(session_in_progress) {
      navigate("/zone/list");
    } else {
      navigate("/start_session");
    }
  });

  return (
    <div/>
  );
}

export default SessionRedirect;
