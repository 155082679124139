import LocalStore from "./LocalStore";
import React, { createContext, useState, useContext } from "react";

const key = LocalStore.getPublicKey();
const initial = {
  session_key: key ? key : null,
  session_start_date: key ? parseInt(LocalStore.getStartDate()) : null,
  zones: key ? LocalStore.getZones() : null
};

export const SessionContext = createContext(initial);
function SessionProvider(props) {
  const [session_key, s_session_key] = useState(initial.session_key);
  const [session_start_date, s_session_start_date] = useState(initial.session_start_date);
  const [zones, s_zones] = useState(initial.zones);
  const [pending_zone_name, s_pending_zone_name] = useState(null);

  function is_session_active() {
    return session_key !== null;
  }

  function start_session(public_key) {
    const now = Date.now();
    s_session_key(public_key);
    s_session_start_date(now);
    s_zones([]);
    s_pending_zone_name(null);
    LocalStore.setPublicKey(public_key);
    LocalStore.setStartDate(now);
    LocalStore.setZones([]);
  }

  function end_session() {
    s_session_key(null);
    s_session_start_date(null);
    s_zones(null);
    s_pending_zone_name(null);
    LocalStore.removePublicKey();
    LocalStore.removeStartDate();
    LocalStore.removeZones();
  }

  function add_zone(zone) {
    const new_value = [...zones, zone];
    s_zones(new_value);
    LocalStore.setZones(new_value);
    return zones.length;
  }

  function update_zone(zone, index) {
    const copy = [...zones];
    copy[index] = zone;
    s_zones(copy);
    LocalStore.setZones(copy);
  }

  function remove_zone(index) {
    const copy = [...zones];
    copy.splice(index, 1);
    s_zones(copy);
    LocalStore.setZones(copy);
  }

  const session_data = {
    session_key,
    session_start_date,
    zones,
    pending_zone_name,
    s_pending_zone_name,
    is_session_active,
    start_session,
    end_session,
    add_zone,
    update_zone,
    remove_zone
  };

  return <SessionContext.Provider value={session_data} {...props} />;
}

export function useSessionContext() {
  return useContext(SessionContext);
}

export default SessionProvider;
