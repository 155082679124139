import React from "react";
import {Button, Grid, Modal} from "@mui/material";

function ConfirmationModal(props) {
  return(
    <Modal
      open={props.open}
      onClose={props.onClose}
    >
      <Grid container spacing={2} sx={{
        position: 'absolute',
        top: '50%',
        transform: 'translate(0%, -50%)',
        width: 'calc(100% - 20px);',
        backgroundColor: 'white',
        borderRadius: "10px",
        padding: '10px',
        margin: '10px',
      }}>
        <Grid item xs={12}>
          {props.text}
        </Grid>
        <Grid item xs={6}>
          <Button onClick={props.onClose} variant="outlined" color="secondary" fullWidth>Annuler</Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={props.onConfirm} variant="contained" fullWidth>{props.text_confirm}</Button>
        </Grid>
      </Grid>
    </Modal>
  );
}


export default ConfirmationModal;
