import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import App from './components/App/App';
import SessionNew from "./components/SessionNew/SessionNew";
import Zones from "./components/Zones/Zones";
import Zone from "./components/Zone/Zone";
import ZoneScan from "./components/ZoneScan/ZoneScan";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="start_session" element={<SessionNew />} />
          <Route path="zone/list" element={<Zones />} />
          <Route path="zone/new" element={<Zone />} />
          <Route path="zone/new/scan" element={<ZoneScan />} />
          <Route path="zone/:zone_id" element={<Zone />} />
          <Route path="zone/:zone_id/scan" element={<ZoneScan />} />
        </Route>
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
