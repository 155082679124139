import React, { useEffect } from 'react';

const Timer = (props) => {
    useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      props.terminated();
    }, props.duration * 1000);
    return () => clearInterval(interval);
  }, [props]);

  return (
    <></>
  );
};

export default Timer;
