import {
  useNavigate
} from "react-router-dom";
import {useSessionContext} from "../../stores/SessionProvider";
import {DateTime} from "luxon";
import {Button, Grid} from "@mui/material";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import {useState} from "react";
import {compute_export} from "../../libs/zip";

function Zones() {
  const navigate = useNavigate();
  const session_context = useSessionContext();
  const [session_end_modal, s_session_end_modal] = useState(false);
  const [empty_zone_modal, s_empty_zone_modal] = useState(false);
  const [email_send_modal, s_email_send_modal] = useState(false);

  const date = DateTime.fromMillis(session_context.session_start_date);

  function get_zone(index) {
    navigate(`/zone/${index}`);
  }

  function add_zone() {
    navigate('/zone/new');
  }

  function reset() {
    s_session_end_modal(true);
  }

  function on_session_end_confirmed() {
    s_session_end_modal(false);
    session_context.end_session();
    navigate('/');
  }

  function start_export() {
    const lengths = session_context.zones.map(z => z.devices.length);
    if(lengths.includes(0)) {
      s_empty_zone_modal(true);
    } else {
      on_empty_zones_confirmed();
    }
  }

  function on_empty_zones_confirmed() {
    s_empty_zone_modal(false);
    s_email_send_modal(true);
  }

  function on_send_confirm() {
    s_email_send_modal(false);
    let link = `mailto:${process.env.REACT_APP_MAIL_RECIPIENTS.split('%').join('@')}?`;
    if(process.env.REACT_APP_MAIL_RECIPIENTS_CC) {
      link += `cc=${process.env.REACT_APP_MAIL_RECIPIENTS_CC.split('%').join('@')}`;
    }
    link += `&subject=${encodeURIComponent("Invithings installation")}`;
    const body = "Bonjour\n\n" +
      "Vous allez transmettre les données de vos capteurs afin de pouvoir finaliser l'installation.\n\n" +
      "Merci de joindre le fichier que vous venez de télécharger.\n" +
      "(Si le téléchargement ne s'est pas lancé automatiquement, vous pouvez coller le contenu du presse papier)\n\n" +
      "Cordialement,\n" +
      "L'équipe Invissys";
    link += `&body=${encodeURIComponent(body)}`;

    compute_export(session_context.session_start_date, session_context.session_key, session_context.zones)
      .then(_ => window.location.href = link);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        Session du {date.toLocaleString(DateTime.DATETIME_SHORT)}
      </Grid>
      <Grid item xs={6}>
        <Button onClick={reset} variant="contained" fullWidth>Réinitialiser la session</Button>
      </Grid>
      <ConfirmationModal
        open={session_end_modal}
        onClose={() => s_session_end_modal(false)}
        onConfirm={on_session_end_confirmed}
        text="En réinitialisant la session, vous perdrez toutes les zones scannées. Souhaitez-vous continuer ?"
        text_confirm="Réinitialiser"
      />

      {session_context.zones.map((zone, index) => {
        const text = <span>
          <h3 style={{display: 'inline'}}>{zone.name}</h3>
          <p style={{display: 'inline', 'marginLeft': '10px'}}>{zone.devices.length} capteur{zone.devices.length > 1 ? 's' : ''}</p>
        </span>;
        return <Grid item xs={12} key={index}>
          <Button onClick={() => get_zone(index)}
                  variant="outlined"
                  color="secondary"
                  fullWidth>
            {text}
          </Button>
        </Grid>
      })}

      <Grid item xs={12}>
        <Button onClick={add_zone}
                variant="contained"
                color="secondary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                fullWidth>
          Ajouter une zone
        </Button>
      </Grid>

      {session_context.zones.length > 0 &&
        <Grid item xs={12}>
          <Button onClick={start_export}
                  variant="outlined"
                  color="secondary"
                  startIcon={<CloudUploadIcon />}
                  fullWidth>
            Validation des zones
          </Button>
        </Grid>
      }
      <ConfirmationModal
        open={empty_zone_modal}
        onClose={() => s_empty_zone_modal(false)}
        onConfirm={on_empty_zones_confirmed}
        text="Certaines zones sont vides. Elles ne seront pas créées. Souhaitez-vous continuer ?"
        text_confirm="Continuer"
      />
      <ConfirmationModal
        open={email_send_modal}
        onClose={() => s_email_send_modal(false)}
        onConfirm={on_send_confirm}
        text="Vous allez procéder auprès d'Invissys à la demande des informations requises pour finaliser le pairage des capteurs. Souhaitez-vous continuer ?"
        text_confirm="Envoyer"
      />
    </Grid>
  );
}

export default Zones;
