import './SessionNew.css';
import {QrReader} from "react-qr-reader";
import {
  useNavigate,
} from "react-router-dom";
import {useSessionContext} from "../../stores/SessionProvider";
import {useState} from "react";
import {Grid} from "@mui/material";
import Timer from "../Timer/Timer";

function SessionNew() {
  const navigate = useNavigate();
  const session_context = useSessionContext();
  const [invalid, s_invalid] = useState(false);

  function on_result(result, _error) {
    if (!!result) {
      const pub_key_regex = /^-----BEGIN RSA PUBLIC KEY-----(.*)-----END RSA PUBLIC KEY-----\r?\n?$/s;
      if(pub_key_regex.test(result.text)){
        session_context.start_session(result.text);
        navigate("/zone/list");
      } else {
        s_invalid(true);
      }
    }
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <ol style={{margin: 0, padding: '12px 12px 12px 18px'}}>
          <li>Depuis l’interface InviThings Exploitation, cliquez sur le bouton “Importer”</li>
          <li>Scannez le QR code de sécurité avec votre smartphone</li>
        </ol>
      </Grid>
      <Grid item xs={12}>
        { invalid &&
          <div style={{
            position: 'absolute',
            width: 'calc(100% - 48px)',
            borderRadius: "10px",
            padding: '10px',
            zIndex: 1,
            backgroundColor: '#e1694e'
          }}>
            <Timer duration={5} terminated={() => s_invalid(false)}/>
            <div>Clé publique invalide. Veuillez-scanner le QR code d'Invithings exploitation, section import.</div>
          </div>
        }
        <QrReader
          scanDelay={100}
          constraints={{ facingMode: 'environment' }}
          onResult={on_result}
          style={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  );
}

export default SessionNew;
