const PUBLIC_KEY_KEY = "session_public_key";
const START_DATE_KEY = "session_start_date";
const ZONES_KEY = "session_zones";

class LocalStore {
  setPublicKey(public_key) { localStorage.setItem(PUBLIC_KEY_KEY, public_key); }
  getPublicKey() { return localStorage.getItem(PUBLIC_KEY_KEY); }
  removePublicKey() { return localStorage.removeItem(PUBLIC_KEY_KEY); }

  setStartDate(date) { localStorage.setItem(START_DATE_KEY, date); }
  getStartDate() { return localStorage.getItem(START_DATE_KEY); }
  removeStartDate() { return localStorage.removeItem(START_DATE_KEY); }

  setZones(zones) { localStorage.setItem(ZONES_KEY, JSON.stringify(zones)); }
  getZones() { return JSON.parse(localStorage.getItem(ZONES_KEY)); }
  removeZones() { return localStorage.removeItem(ZONES_KEY); }
}

export default new LocalStore();
