import JSZip from "jszip";
import FileSaver from "file-saver";
import {DateTime} from "luxon";
import {is_iOS} from "./platform";
import copy from 'copy-to-clipboard';

export function compute_export(date, session_key, zones) {
  return new Promise((resolve, reject) => {
    const zip = new JSZip();
    zip.file("key.pub", session_key);
    let flat_file = '';
    for (let i = 0; i < zones.length; i++) {
      const zone = zones[i];
      for (let i = 0; i < zone.devices.length; i++) {
        flat_file += `${zone.devices[i]},${zone.name.replace(',', ' ')},${i + 1}\n`;
      }
    }
    zip.file("devices.csv", flat_file);
    zip.generateAsync({type:"blob"}).then(function(content) {
      const date_string = DateTime.fromMillis(date).toFormat('dd-MM-yyyy_HH-mm');
      const name = `invithings_sensors_${date_string}.zip`;
      copy(session_key + '\n\n\n\n' + flat_file);
      if(is_iOS()) {
        const a = document.createElement('a');
        a.download = name;
        a.href = URL.createObjectURL(content);
        a.target = '_blank';
        a.download = `invithings_sensors_${date_string}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        resolve();
      } else {
        FileSaver.saveAs(content, name);
        resolve();
      }
    });
  })
}
