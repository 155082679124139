import {
  useParams,
  useNavigate
} from "react-router-dom";
import {Button, Grid, TextField} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import {useState} from "react";
import {useSessionContext} from "../../stores/SessionProvider";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

function Zone() {
  const params = useParams();
  const session_context = useSessionContext();
  const navigate = useNavigate();
  const is_creation = params.zone_id === undefined;
  const zone_index = is_creation ? null : parseInt(params.zone_id);
  const initial_name = is_creation ? '' : get_current_zone().name;
  const scanned_devices = is_creation ? [] : get_current_zone().devices;
  const scanned_count = scanned_devices.length;
  const [zone_name, s_zone_name] = useState(initial_name);
  const [zone_removal_modal, s_zone_removal_modal] = useState(false);

  function get_current_zone() {
    return session_context.zones[zone_index];
  }
  function on_zone_name_change(event) {
    s_zone_name(event.target.value);
  }

  function is_zone_name_invalid() {
    return zone_name === '';
  }

  function save() {
    if(is_creation) {
      session_context.add_zone({name: zone_name, devices: []});
    } else {
      const initial_data = get_current_zone();
      initial_data.name = zone_name;
      session_context.update_zone(initial_data, zone_index);
    }
    navigate("/zone/list");
  }

  function scan() {
    if(is_creation) {
      session_context.s_pending_zone_name(zone_name);
      navigate("/zone/new/scan");
    } else {
      if(get_current_zone().name !== zone_name) {
        session_context.s_pending_zone_name(zone_name);
      }
      navigate(`/zone/${zone_index}/scan`)
    }
  }

  function remove() {
    if(!is_creation) {
      session_context.remove_zone(zone_index);
    }
    navigate("/zone/list");
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Button onClick={save} variant="contained" fullWidth color="success"
                disabled={is_zone_name_invalid()}>
          Sauvegarder et quitter
        </Button>
      </Grid>
      <Grid item xs={12}>
        <h4>Nom de la zone</h4>
      </Grid>
      <Grid item xs={12}>
        <TextField variant="outlined" color="secondary" placeholder="zone" fullWidth value={zone_name} onChange={on_zone_name_change}/>
      </Grid>
      <Grid item xs={12}>
        <p>{scanned_count} capteur{scanned_count > 1 ? 's' : ''} scanné{scanned_count > 1 ? 's' : ''}</p>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={scan}
                variant="contained"
                color="secondary"
                fullWidth
                disabled={is_zone_name_invalid()}
                startIcon={<PhotoCameraOutlinedIcon />}>
          Scanner les capteurs
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={() => s_zone_removal_modal(true)} variant="contained" fullWidth startIcon={<DeleteIcon />}>Supprimer la zone</Button>
      </Grid>
      {scanned_devices.map((device, index) =>
        <Grid item xs={12} key={index}>
          <TextField variant="outlined" color="secondary" disabled fullWidth value={device}/>
        </Grid>
      )}
      <ConfirmationModal
        open={zone_removal_modal}
        onClose={() => s_zone_removal_modal(false)}
        onConfirm={remove}
        text="Supprimer la zone ?"
        text_confirm="Supprimer"
      />
    </Grid>
  );
}

export default Zone;
