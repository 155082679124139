import {createTheme} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E35241",
    },
    secondary: {
      main: "#000000",
    },
  },
});

export default theme;
