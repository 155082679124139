import { useLocation, useNavigate } from "react-router-dom";
import {QrReader} from "react-qr-reader";
import {Button, Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {useSessionContext} from "../../stores/SessionProvider";
import Timer from "../Timer/Timer";

function ZoneScan() {
    const [scanned_devices, s_scanned_devices] = useState([]);
    const [last_scan, s_last_scan] = useState(null);
    const [last_scan_status, s_last_scan_status] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const session_context = useSessionContext();
    const is_new_zone = location.pathname.search('new') !== -1;
    let initial_device_count = 0;
    if(!is_new_zone) {
      initial_device_count = get_current_zone().devices.length;
    }

    useEffect(() => {
      on_scan(last_scan);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [last_scan]);

  function get_current_zone_index() {
    const regex = /zone\/(\d+)\/scan/i;
    const match = location.pathname.match(regex);
    return parseInt(match[1]);
  }

  function get_current_zone() {
    const index = get_current_zone_index();
    return session_context.zones[index];
  }

  function get_current_zone_with_index() {
    const index = get_current_zone_index();
    return [index, session_context.zones[index]];
  }

  function check_scan_in_other_zones(text) {
    for (const zone of session_context.zones) {
      if(zone.devices.indexOf(text) !== -1) {
        return zone;
      }
    }
    return null;
  }

  function save(){
    if(is_new_zone) {
      const index = session_context.add_zone({
        name: session_context.pending_zone_name,
        devices: scanned_devices
      });
      session_context.s_pending_zone_name(null);
      navigate(`/zone/${index}`);
    } else {
      const [index, current_zone] = get_current_zone_with_index();
      current_zone.devices = [...current_zone.devices, ...scanned_devices];
      if(session_context.pending_zone_name !== null) {
        current_zone.name = session_context.pending_zone_name;
        session_context.s_pending_zone_name(null);
      }
      session_context.update_zone(current_zone, index);
      navigate(`/zone/${index}`);
    }
  }

  function on_result(result, _error, _instance) {
    if (!!result) {
      s_last_scan(result.text);
    }
  }

  function on_scan(text) {
    if(text === null) {
      return;
    }
    if(last_scan_status !== null) {
      return;
    }
    if(text.length !== 16) {
      s_last_scan_status({status: 'invalid', data: text});
      return;
    }
    if(scanned_devices.indexOf(text) !== -1 || (!is_new_zone && get_current_zone().devices.indexOf(text) !== -1)) {
      s_last_scan_status({status: 'duplicate', data: text});
      return;
    }
    const duplicate_zone = check_scan_in_other_zones(text);
    if(duplicate_zone !== null) {
      s_last_scan_status({status: 'duplicate_zone', data: duplicate_zone});
      return;
    }
    s_scanned_devices(array => [...array, text]);
    s_last_scan_status({status: 'success', data: text.substring(text.length, -6)});
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Button onClick={save} variant="contained" fullWidth color="success">
          Sauvegarder et quitter
        </Button>
      </Grid>
      <Grid item xs={12}>
        <p>Nombre capteurs dans la zone : {initial_device_count + scanned_devices.length}</p>
      </Grid>
      <Grid item xs={12} sx={{position: 'relative'}}>
        { last_scan_status !== null &&
          <div style={{
            position: 'absolute',
            width: 'calc(100% - 32px)',
            borderRadius: "10px",
            padding: '10px',
            zIndex: 1,
            backgroundColor: last_scan_status.status === 'success' ? '#e8f2e1' : last_scan_status.status === 'duplicate' ? '#feeccd' : '#e1694e'
          }}>
            <Timer duration={5} terminated={() => { s_last_scan_status(null); s_last_scan(null);}}/>
            {last_scan_status.status === 'success' &&
              <div>Capteur scanné : {last_scan_status.data}</div>
            }
            {last_scan_status.status === 'duplicate' &&
              <div>Capteur déjà scanné</div>
            }
            {last_scan_status.status === 'duplicate_zone' &&
              <div>Capteur déjà scanné ({last_scan_status.data.name})</div>
            }
            {last_scan_status.status === 'invalid' &&
              <div>Données invalies</div>
            }
          </div>
        }
        <QrReader
          scanDelay={200}
          constraints={{ facingMode: 'environment' }}
          onResult={on_result}
          style={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  );
}

export default ZoneScan;
